import React from 'react';
import posed, { PoseGroup } from 'react-pose';

const AnimatablePath = posed.path({
    enter: {
        scale: 1,
        rotate: 0,
    },
    exit: {
        scale: 0,
        rotate: -90,
    },
    preEnter: {
        scale: 0,
        rotate: 90,
    },
});
const AnimatableSVG = posed.svg({
    enter: {
        delayChildren: 300,
        staggerChildren: 300,
    },
    exit: {
        staggerChildren: 100,
    },
});
// generated by machine
const SvgComponent = (props) => (
    <AnimatableSVG viewBox="0 0 51.77 51.77" {...props}>
        <title>NotFound</title>
        <PoseGroup animateOnMount preEnterPose={'preEnter'}>
            <AnimatablePath
                key={1}
                d="M34.57 5.92a20.26 20.26 0 1 0-2.77 30.95A4.31 4.31 0 0 0 33 39l11.5 11.5a4.28 4.28 0 0 0 6-6L39 33a4.3 4.3 0 0 0-2.15-1.16 20.27 20.27 0 0 0-2.28-25.92zm-3.63 25a15.12 15.12 0 1 1 0-21.38 15.14 15.14 0 0 1 0 21.4z"
            />
            <AnimatablePath
                key={2}
                d="M21.91 13.11a3.79 3.79 0 0 1 .89 4.33 4.52 4.52 0 0 1-.68 1.14l-1 1.3a8.84 8.84 0 0 0-1.72 3.18 9.36 9.36 0 0 0 0 3.37l2.55-.36a7.23 7.23 0 0 1 0-2.79 8.07 8.07 0 0 1 1.47-2.52 19.54 19.54 0 0 0 2.11-3.14 4.44 4.44 0 0 0 .28-2.55 5.48 5.48 0 0 0-2.29-3.88 6.33 6.33 0 0 0-4.75-1 6.31 6.31 0 0 0-4.54 2.57 7.11 7.11 0 0 0-1 5.23l2.55-.36a7.14 7.14 0 0 1 .18-2.94A3.45 3.45 0 0 1 19 12.47a3.41 3.41 0 0 1 2.91.64zM19.67 28.817l2.852-.407.425 2.98-2.851.407z"
            />
        </PoseGroup>
    </AnimatableSVG>
);

export default SvgComponent;
