import React from 'react';

const SvgComponent = (props) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 725.67 725.67"
        {...props}
    >
        <defs>
            <style>{`.cls-4{fill:#fff}`}</style>
        </defs>
        <title>{`Vantaa-Logo-City-Symbol-RGB-FIN_19235`}</title>
        <path
            fill="#14489e"
            d="M385.51 0L0 362.83l408.19 362.84 317.48-317.48L385.51 0z"
        />
        <path
            fill="#488cca"
            d="M0 362.83l362.83-249.44 362.84 294.8-317.48 317.48L0 362.83z"
        />
        <path
            fill="#89cbf0"
            d="M725.67 408.19l-385.51-204.1L0 362.83l408.19 362.84 317.48-317.48z"
        />
        <g>
            <path
                className="cls-4"
                d="M380.72 655.46H357.1l-39.62-110.94h27.42l24.1 78.3 23.93-78.3h27.89zM492.29 579.07v76.39H468v-10.77c-3.65 7-13.47 12.68-23.3 12.68-22 0-37.24-17.28-37.24-40.1s15.21-40.1 37.24-40.1c9.83 0 19.65 5.87 23.3 12.84v-10.94zM468 617.27c0-10.46-7.29-19.49-18.23-19.49s-18.22 9-18.22 19.49c0 10.62 7.29 19.49 18.22 19.49S468 627.89 468 617.27zM501 579.07h24.25v11.1c4.28-8.24 13.15-13 25-13 9.83 0 17.27 3.33 21.4 8.72 4.75 5.86 6 13.31 6 24.25v45.32h-24.19v-39.14c0-10.94-3.33-18.54-13.47-18.54-9.83 0-14.74 7.6-14.74 19.33v38.35H501zM637.77 655.46a93.85 93.85 0 0 1-16.16 1.27c-11.41 0-27.11-1.42-27.11-26.94v-32.65h-12v-18.07h12v-22.66h24.1v22.66H636v18.07h-17.4v27.26c0 11.25 4.43 13 10.45 13a69.7 69.7 0 0 0 8.72-.48zM725.25 579.07v76.39H701v-10.77c-3.64 7-13.47 12.68-23.29 12.68-22 0-37.25-17.28-37.25-40.1s15.22-40.1 37.25-40.1c9.82 0 19.65 5.87 23.29 12.84v-10.94zM701 617.27c0-10.46-7.29-19.49-18.22-19.49s-18.23 9-18.23 19.49c0 10.62 7.29 19.49 18.23 19.49S701 627.89 701 617.27zM816.38 579.07v76.39h-24.25v-10.77c-3.65 7-13.47 12.68-23.3 12.68-22 0-37.24-17.28-37.24-40.1s15.21-40.1 37.24-40.1c9.83 0 19.65 5.87 23.3 12.84v-10.94zm-24.25 38.2c0-10.46-7.29-19.49-18.23-19.49s-18.22 9-18.22 19.49c0 10.62 7.29 19.49 18.22 19.49s18.23-8.87 18.23-19.49z"
                transform="translate(-204.1 -204.1)"
            />
        </g>
    </svg>
);

export default SvgComponent;
