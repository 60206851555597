import React from 'react';

// generated by code
const SvgComponent = (props) => (
    <svg viewBox="0 0 52 52" {...props}>
        <path d="M38.853 5.324c-7.098-7.098-18.607-7.098-25.706 0C6.751 11.72 6.031 23.763 11.459 31L26 52l14.541-21c5.428-7.237 4.708-19.28-1.688-25.676zM26.177 24a6 6 0 1 1 0-12 6 6 0 0 1 0 12z" />
    </svg>
);

export default SvgComponent;
