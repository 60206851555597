import React from 'react';
// generated by tools
const SvgComponent = (props) => (
    <svg viewBox="0 0 15.642 15.642" {...props}>
        <path
            fillRule="evenodd"
            d="M8.882 7.821l6.541-6.541A.75.75 0 1 0 14.362.219L7.821 6.76 1.28.22A.75.75 0 1 0 .219 1.281L6.76 7.822l-6.54 6.54a.75.75 0 0 0 1.06 1.061l6.541-6.541 6.541 6.541a.75.75 0 1 0 1.06-1.061l-6.54-6.541z"
        />
    </svg>
);

export default SvgComponent;
